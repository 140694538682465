import React from 'react';
import content from './content';
import { Container } from 'react-bootstrap';
import MetaTags from "../MetaTags";
import Hero from '../Hero';
function createMarkup() {
    return {
       __html: `<div> ${content} </div>`   };
 }; 


export default function TermsContent(params) {
    return (
        <>
            {/* <Hero empty /> */}
            <Container className="text-light py-5">
                <MetaTags/>
                
                <div dangerouslySetInnerHTML={createMarkup()}>
                </div>
            </Container>
        </>
    )
}